import React, { useState } from 'react';
import { Badge, Tooltip } from 'reactstrap';

export function statusColumn() {
  function HeaderFormatter() {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
      <>
        <span id="statusHeader">Status</span>
        <Tooltip
          delay={1500}
          placement="top"
          isOpen={tooltipOpen}
          target="statusHeader"
          toggle={toggleTooltip}
          style={{
            width: '300px',
            maxWidth: '300px',
            whiteSpace: 'normal'
          }}
        >
          Click to move New Reports to top
        </Tooltip>
      </>
    );
  }

  const stateFormatter = (dataField, row) => {
    if (row) {
      switch (row.status.toLowerCase()) {
        case 'new':
          return (
            <Badge color="soft-primary" className="fs--1" pill>
              New
            </Badge>
          );
        case 'initiated':
          return (
            <Badge color="soft-info" className="fs--1" pill>
              Initiated
            </Badge>
          );
        case 'in progress':
          return (
            <Badge color="soft-warning" className="fs--1" pill>
              In progress
            </Badge>
          );
        case 'complete':
          return (
            <Badge color="soft-success" className="fs--1" pill>
              Completed
            </Badge>
          );
        default:
          return (
            <Badge color="soft-danger" className="fs--1" pill>
              Undefined
            </Badge>
          );
      }
    }
    return null;
  };

  return [
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: <HeaderFormatter />,
      classes: 'border-0 py-2 align-middle',
      formatter: stateFormatter,
      align: 'center',
      headerAlign: 'center',
      sort: true
    }
  ];
}
