import emailService from '../../../../../services/email/email.service';

class PdfManager {
  constructor(pdfDoc) {
    this.pdfDoc = pdfDoc;
  }

  savePdf(fileName = 'generated.pdf') {
    if (this.pdfDoc) {
      this.pdfDoc.save(`${fileName}.pdf`);
    }
  }

  showPdf() {
    if (this.pdfDoc) {
      const pdfUrl = this.pdfDoc.output('bloburl');
      window.open(pdfUrl);
    }
  }

  printPdf() {
    if (this.pdfDoc) {
      this.pdfDoc.autoPrint({ variant: 'non-conform' });
      window.open(URL.createObjectURL(this.pdfDoc.output('blob')));
    }
  }

  sendEmailWithPdf(email, fileName = 'generated.pdf') {
    var file = this.pdfDoc.output('blob');

    return emailService.sendReportEmail(file, email, `${fileName}.pdf`);
  }
}

export default PdfManager;
