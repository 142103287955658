export const homeRoutes = {
  name: 'Dashboard',
  to: '/',
  exact: true,
  icon: 'chart-pie',
};

export const organizationRoutes = {
  name: 'Companies',
  to: '/agency/agencies',
  icon: 'building',
  roles: ['super']
};

export const departmentRoutes = {
  name: 'Departments',
  to: '/group/groups',
  icon: 'users',
  roles: ['superadmin', 'admin']
};

export const specialtyRoutes = {
  name: 'Specialties',
  to: '/specialty/specialties',
  icon: 'medkit',
  roles: ['super', 'superadmin', 'admin']
};

export const userRoutes = {
  name: 'Admins',
  to: '/user/users',
  icon: 'user-cog',
  roles: ['super', 'superadmin']
};

export const cliniciansRoutes = {
  name: 'Testers',
  to: '/tester/testers',
  icon: 'user-md',
  roles: ['super', 'superadmin', 'admin']
};

export const reportsRoutes = {
  name: 'Reports',
  to: '/reports',
  icon: 'file-alt',
  roles: ['super', 'superadmin', 'admin'],
};

export const contactSupportRoutes = {
  name: 'Resources',
  to: '/contactsupport',
  icon: 'comments',
  roles: ['super', 'superadmin', 'admin'],
};

export default [
  homeRoutes,
  organizationRoutes,
  departmentRoutes,
  specialtyRoutes,
  userRoutes,
  cliniciansRoutes,
  reportsRoutes,
  contactSupportRoutes
];
