import React from 'react'

export const AssessmentTermsConditions = () => {
    return (
        <p>
            <strong>Terms & Conditions - User Rights: </strong> Escoreit, LLP will not distribute 
            the User Cases or Customer Information, except for troubleshooting and internal development 
            processes for GURU LLP support. Escoreit, LLP will not otherwise engage in the distribution 
            of content, data, or user profiles. The entities of Escoreit, LLP and GURU, LLP adhere to 
            the privacy act code of conduct.
        </p>
    )
}
