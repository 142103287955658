import React from 'react';
import PropTypes from 'prop-types';

const LogoText = ({ organizationName }) => {
  return (
    <div id="logotext" className="m-0 p-0">
      <div className="d-block fs-1 text-black mt-0 mb-0 pb-0">{organizationName}</div>
    </div>
  );
};

LogoText.propTypes = {
  organizationName: PropTypes.string.isRequired
};

export default LogoText;
