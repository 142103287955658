import jsPDF from 'jspdf';

const PdfGenerator = () => {
  const additionalVerticalSpacing = 4;
  const additionalHorizontalSpacing = 1.5;

  const createPdf = () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'cm',
      format: 'a4',
      compress: true,
      putOnlyUsedFonts: true,
      floatPrecision: 16 // or "smart", default is 16
    });

    return doc;
  };

  return { createPdf, additionalVerticalSpacing, additionalHorizontalSpacing };
};

export default PdfGenerator;
