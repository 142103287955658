import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { isTruck } from '../../../../helpers/hcutils';

export function reportIdColumn(organizationType) {
  function HeaderFormatter() {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
      <span>
        <span id="reportIDHeader">Report ID</span>
        <Tooltip
          delay={1500}
          placement="top"
          isOpen={tooltipOpen}
          target="reportIDHeader"
          toggle={toggleTooltip}
          style={{
            width: '300px',
            maxWidth: '300px',
            whiteSpace: 'normal'
          }}
        >
          Sort by report ID number
        </Tooltip>
      </span>
    );
  }

  return isTruck(organizationType)
    ? [
        {
          dataField: 'clinicianAssessmentId',
          headerClasses: 'border-0',
          text: <HeaderFormatter />,
          classes: 'border-0 py-2 align-middle',
          sort: true
        }
      ]
    : [];
}
