import axios from 'axios';
import authHeader from '../auth-header';
import { toast } from 'react-toastify';

import { API_URL } from "../../config";

const EXAM_API_URL = API_URL + "/email";

class EMailService {
    sendReportEmail(reportFile, email, reportName) {
        const params = new FormData();
        params.append('file', reportFile);
        params.append('email', email);
        params.append('reportName', reportName.replaceAll(' ', '_'));

        let headers = { ...authHeader() };

        return axios
            .post(EXAM_API_URL + '/send-report-email',
                params,
                { headers: headers }
            )
            .then(response => {
                toast.success("Message was sent successfully");
                return response.data;
            });
    }

    sendAttachmentEmail(reportFile, email, reportName, companyName = '') {
        const params = new FormData();
        params.append('file', reportFile);
        params.append('email', email);
        params.append('reportName', reportName.replaceAll(' ', '_'));
        params.append('company', companyName);

        let headers = { ...authHeader() };

        return axios
            .post(EXAM_API_URL + '/send-attachment-email',
                params,
                { headers: headers }
            )
            .then(response => {
                toast.success("Message was sent successfully");
                return response.data;
            });
    }
}
export default new EMailService();
