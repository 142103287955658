import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './styles.css';

const CustomToolTip = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { onMouseEnter, onMouseLeave, children, ...rest } = props;

  const defaultOnMouseEnter = () => {};
  const defaultOnMouseLeave = () => {};

  const handleMouseEnter = onMouseEnter || defaultOnMouseEnter;
  const handleMouseLeave = onMouseLeave || defaultOnMouseLeave;

  return (
    <Tooltip
      isOpen={tooltipOpen}
      toggle={toggle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
