import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

// Importamos la librería:
import { useIdleTimer } from 'react-idle-timer';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

import { getItemFromStore, setItemToStore } from './helpers/utils';

const TIME_IN_MINUTES = 10 * 60 * 1000; // 10 minutos en milisegundos

const App = () => {

  // Callback que se invoca cuando se detecta inactividad
  const handleOnIdle = () => {
    console.log('Usuario inactivo durante 10 minutos, procediendo a cerrar sesión...');

    setItemToStore('currentuser', null);
    setItemToStore('reportCompanyFilter', '');
    setItemToStore('reportPeriodType', 'day');
    setItemToStore('reportSelectedOrganizationId', 0);
    setItemToStore('reportSelectedOrganizationName', '');
    setItemToStore('reportExamResultFilter', '');

    window.location.href = '/authentication/login';
  };

  // Inicializamos la detección de inactividad con la librería
  useIdleTimer({
    timeout: TIME_IN_MINUTES,  // Tiempo de inactividad
    onIdle: handleOnIdle,  // Callback al cumplirse el timeout
    debounce: 500,         // Para evitar llamados múltiples
  });

  /**
   * useEffect existente para limpiar el cache al cerrar la ventana
   */
  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      let currentuser = getItemFromStore('currentuser', null);
      if (currentuser) {
        setItemToStore('currentuser', null);
      }
      setItemToStore('reportCompanyFilter', '');
      setItemToStore('reportPeriodType', 'day');
      setItemToStore('reportSelectedOrganizationId', 0);
      setItemToStore('reportSelectedOrganizationName', '');
      setItemToStore('reportExamResultFilter', '');
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
