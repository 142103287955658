import { getFormattedDate } from '../../../../../helpers/hcutils';
import { isIterableArray } from '../../../../../helpers/utils';
import PdfGenerator from './PdfGenerator';
import { reportCustomerInformation, reportFormSummary, reportSalespersonInformation } from './reportutils';

const UserFormPDF = params => {
  const { createPdf, additionalVerticalSpacing, additionalHorizontalSpacing } = PdfGenerator();
  const { avatar, data, contact } = params;

  const createPdfWithUserData = async () => {
    const report = createPdf();
    let x = additionalHorizontalSpacing;
    let y = 3.5;

    const logo = isIterableArray(avatar) ? avatar[0].base64 || avatar[0].src : '';
    await reportHeader(report, x, y, data, logo); // Esperar a que reportHeader complete

    y += 2;
    reportSalespersonInformation(report, x, y, data);

    y += 2.8;
    report.line(x, y, x + 18.5, y, 'F');

    y += 0.6;
    reportCustomerInformation(report, x, y, contact || '');

    y += 4.0;
    report.line(x, y, x + 18.5, y, 'F');

    x = additionalHorizontalSpacing;
    y += 0.2;
    reportFormSummary(report, x, y, data);

    return report;
  };

  const reportHeader = (report, x, y, data, logo = null) => {
    return new Promise((resolve, reject) => {
      const addText = (fontSize, text, xOffset = 0, yOffset = 0) => {
        report
          .setFontSize(fontSize)
          .setFont(undefined, 'bold')
          .text(text, x + xOffset, y + yOffset, { maxWidth: 18 });
      };

      if (logo) {
        const image1 = new Image();
        image1.src = logo;

        image1.onload = () => {
          const imageDimension = imageTransform(image1, 120, 80);

          report.setFillColor(233, 236, 249);
          report.rect(0, 0, 100, 4.7, 'F');
          report.addImage(image1, 'JPEG', x, y - imageDimension.height, imageDimension.width, imageDimension.height);

          addText(11, data.organization.organizationName, imageDimension.width + 0.2, -imageDimension.height / 2);
          addText(11, 'Report ID:' + data.reportdata.clinicianAssessmentId.toString(), 0, 0.8);

          report
            .setFontSize(9)
            .setFont(undefined, 'normal')
            .text(getFormattedDate(), 20, y + 0.8, { maxWidth: 8, align: 'right' });

          resolve(report); // Resuelve la promesa con el report después de agregar la imagen y el texto al PDF
        };

        image1.onerror = error => {
          reject(error); // Rechaza la promesa si hay un error al cargar la imagen
        };
      } else {
        report.setFillColor(233, 236, 249);
        report.rect(0, 0, 100, 4.7, 'F');

        addText(11, data.organization.organizationName);
        addText(11, 'Report ID:' + data.reportdata.clinicianAssessmentId.toString(), 0, 0.8);

        report
          .setFontSize(9)
          .setFont(undefined, 'normal')
          .text(getFormattedDate(), 20, y + 0.8, { maxWidth: 8, align: 'right' });

        resolve(report); // Resuelve la promesa con el report si no se proporciona un logo
      }
    });
  };

  const imageTransform = (img, width, height) => {
    const anchoOriginal = img.width;
    const altoOriginal = img.height;
    const relacionAspecto = anchoOriginal / altoOriginal;
    let anchoImagen = 0;
    let altoImagen = 0;

    if (relacionAspecto > width / height) {
      anchoImagen = width;
      altoImagen = width / relacionAspecto;
    } else {
      anchoImagen = height * relacionAspecto;
      altoImagen = height;
    }
    return {
      width: ConvertPixelToCm(anchoImagen),
      height: ConvertPixelToCm(altoImagen)
    };
  };

  const ConvertPixelToCm = (pixeles, ppi = 96) => {
    const pcm = ppi / 2.54;
    return pixeles / pcm;
  };

  return {
    createPdfWithUserData
  };
};

export default UserFormPDF;
