import React, { useState, useRef } from 'react';
import {
  Badge,
  Dropdown, // Usamos Dropdown controlado en lugar de UncontrolledDropdown
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ExamService from '../../../services/exam/exam.service';
import './styles.css';

const HoverableBadge = ({ row, handle_OnShowReport, handle_OnDownloadReport, handle_AddContact, handle_EditExam }) => {
  // Estado para controlar el dropdown y el tooltip
  const [dropdownOpen, setDropdownOpen] = useState(false); // Saber si el menú está abierto
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('No data...');

  // Refs para manejar el delay y el hover
  const timerRef = useRef(null);
  const isHovering = useRef(false);

  const tooltipId = `hoverable-badge-tooltip-${row.clinicianAssessmentId}`;

  // Maneja el toggle (abrir/cerrar) del dropdown
  const handleToggleDropdown = () => {
    // Si lo abrimos/cerramos, forzamos el cierre del tooltip
    setDropdownOpen(prev => !prev);
    setTooltipOpen(false);
    isHovering.current = false;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  // Mouse entra
  const handleMouseEnter = () => {
    // Si el menú está abierto, NO mostramos el tooltip
    if (dropdownOpen) return;

    isHovering.current = true;
    timerRef.current = setTimeout(async () => {
      if (isHovering.current) {
        try {
          // Llamada al backend
          const response = await ExamService.getPreview(row.clinicianAssessmentId);
          console.log('Respuesta del backend:', response.data);
          // Verificar si el array devuelto está vacío
          if (!response.data || response.data.length === 0) {
            setTooltipContent('No data...');
          } else {
            // Generar contenido del tooltip
            const formattedContent = response.data
              .map(item => `<div><strong>${item.questionText}:</strong> <span>${item.selection}</span></div>`)
              .join('');
            setTooltipContent(formattedContent);
          }
        } catch (error) {
          console.error('Error al obtener los datos del backend:', error);
          setTooltipContent('Error al cargar los datos');
        }
        setTooltipOpen(true); // Muestra el tooltip
      }
    }, 1500);
  };

  // Mouse sale
  const handleMouseLeave = () => {
    isHovering.current = false;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setTooltipOpen(false);
  };

  const examTypeLower = row.examType.toLowerCase();

  // Si es de tipo "form" o "guided", mostramos un menú (ellipsis)
  if (examTypeLower === 'form' || examTypeLower === 'guided') {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={handleToggleDropdown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <DropdownToggle
          color="link"
          size="sm"
          className="text-600 btn-reveal mr-3"
          aria-label="More options"
          id={tooltipId}
        >
          <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
        </DropdownToggle>

        <DropdownMenu right className="border py-2">
          {row.status.toLowerCase() === 'complete' && (
            <DropdownItem onClick={e => handle_OnShowReport(e, row)}>
              <Badge color="info" className="fs--1" pill style={{ cursor: 'pointer' }}>
                View
              </Badge>
            </DropdownItem>
          )}
          {row.status.toLowerCase() === 'complete' && (
            <DropdownItem onClick={e => handle_OnDownloadReport(e, row)}>
              <Badge color="primary" className="fs--1" pill style={{ cursor: 'pointer' }}>
                Download
              </Badge>
            </DropdownItem>
          )}
          <DropdownItem onClick={e => handle_AddContact(e, row)}>
            <Badge color="success" className="fs--1" pill style={{ cursor: 'pointer' }}>
              Contact
            </Badge>
          </DropdownItem>
          {row.status.toLowerCase() === 'complete' && (
            <DropdownItem onClick={e => handle_EditExam(e, row, 'edit')}>
              <Badge color="warning" className="fs--1" pill style={{ cursor: 'pointer' }}>
                Edit
              </Badge>
            </DropdownItem>
          )}
          <DropdownItem onClick={e => handle_EditExam(e, row, 'delete')}>
            <Badge color="danger" className="fs--1" pill style={{ cursor: 'pointer' }}>
              Delete
            </Badge>
          </DropdownItem>
        </DropdownMenu>
        <Tooltip placement="top" isOpen={tooltipOpen} target={tooltipId} innerClassName="custom-tooltip">
          <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
        </Tooltip>
      </Dropdown>
    );
  } else {
    // Si NO es 'form' o 'guided', simplemente mostramos un Badge "View" con tooltip
    return (
      <>
        <Badge
          color="info"
          className="ml-2 fs--1"
          pill
          style={{ cursor: 'pointer' }}
          onClick={e => handle_OnShowReport(e, row)}
          id={tooltipId}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          View
        </Badge>
        <Tooltip placement="top" isOpen={tooltipOpen} target={tooltipId} innerClassName="custom-tooltip">
          <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
        </Tooltip>
      </>
    );
  }
};

HoverableBadge.propTypes = {
  row: PropTypes.object.isRequired,
  handle_OnShowReport: PropTypes.func.isRequired,
  handle_AddContact: PropTypes.func.isRequired,
  handle_EditExam: PropTypes.func.isRequired
};

export default HoverableBadge;
