import { isTruck } from '../../../../helpers/hcutils';

export function typeColumn(organizationType) {
  return isTruck(organizationType)
    ? [
        {
          dataField: 'examName',
          headerClasses: 'border-0',
          text: 'Type',
          classes: 'border-0 py-2 align-middle',
          sort: true
        }
      ]
    : [];
}
