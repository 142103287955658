import React, { useContext, useState, useEffect, Fragment } from 'react';
import {
  Card,
  CardBody,
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import AppContext from '../../../context/Context';
import PageFilterableTable from '../PageFilterableTable';
import ExamDialog from '../question/examDialog';
import UserGuidedForm from '../user/userGuidedForm';
import HcareModal from './modal/HcareModal';
import { AssessmentTermsConditions } from './AssessmentTermsConditions';

import ExamService from '../../../services/exam/exam.service';
import UserService from '../../../services/user.service';
import { useHistory } from 'react-router-dom';
import { useHcareModal } from '../../hooks/useHcareModal';

import HoverableBadge from './HoverableBadge'; // Importa el subcomponente
import userService from '../../../services/user.service';
import fileService from '../../../services/file/file.service';
import UserFormPDF from '../exam/report/helpers/UserFormPDF';
import PdfManager from '../exam/report/helpers/PdfManager';
import { reportIdColumn } from './examlistcolumn/reportIdColumn';
import { descriptionColumn } from './examlistcolumn/descriptionColumn';
import { typeColumn } from './examlistcolumn/typeColumn';
import { statusColumn } from './examlistcolumn/statusColumn';

// Subcomponentes optimizados con React.memo
const ExamResult = React.memo(({ row, onClick }) => (
  <Fragment>
    <Badge
      color={row.examScore.passed ? 'success' : 'danger'}
      className="fs--1 w-75"
      pill
      style={{ cursor: 'pointer' }}
      onClick={e => onClick(e, row)}
    >
      {row.examScore.clinicScore + ' '} {row.examScore.passed ? 'Pass' : 'Fail'}
    </Badge>
  </Fragment>
));
ExamResult.displayName = 'ExamResult';

const CheckListResult = React.memo(({ row, onClick }) => (
  <Fragment>
    <Badge
      color="success"
      className="fs--1 w-75"
      pill
      style={{ cursor: 'pointer' }}
      onClick={e => onClick(e, row)}
    >
      {row.examScore ? ((row.examScore.frequency + row.examScore.proficiency) / 2).toFixed(1) : 'no data'}
    </Badge>
  </Fragment>
));
CheckListResult.displayName = 'CheckListResult';

const ExamList = ({ setListHasChange, status }) => {
  const { currentuser } = useContext(AppContext);

  // Estados para la lista de exámenes
  const [isLoadedExamList, setIsLoadedExamList] = useState(false);
  const [examList, setExamList] = useState([]);
  const [organizationName, setOrganizationName] = useState('');

  // Estados para el diálogo del examen
  const [showExamDialog, setShowExamDialog] = useState(false);
  const [currentExam, setCurrentExam] = useState(null);

  // Estados para términos y condiciones
  const [isOpenTerms, openModalTerms, closeModalTerms] = useHcareModal(false);
  const [accept, setAccept] = useState(false);

  // Estados para el modal de "Add Contact"
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);

  const [organizationType, setOrganizationType] = useState(null);
  const isTruck = () => organizationType?.toLowerCase() === 'truck';

  const [avatar, setAvatar] = useState(null);
  const [examStateChanged, setExamStateChanged] = useState(false);

  // Estados para la confirmación de borrado
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [examToDelete, setExamToDelete] = useState(null);

  // Estado para el formulario de UserGuidedForm
  const [form, setForm] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    email: '',
    username: '',
    rolevalues: null,
    departmentvalue: null,
    specialtyId: null,
    organizationId: -1,
    company: '',
    phoneNumber: '',
    address: ''
  });

  const [errors, setErrors] = useState({});

  const history = useHistory();

  // Función para limpiar el formulario
  const clearForm = () => {
    setForm({
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      username: '',
      rolevalues: null,
      departmentvalue: null,
      specialtyId: null,
      organizationId: -1,
      company: '',
      phoneNumber: '',
      address: ''
    });
    setErrors({});
  };

  // Función para alternar el modal de "Add Contact"
  const toggleAddContactModal = () => {
    setIsAddContactModalOpen(!isAddContactModalOpen);
  };

  // Función para manejar el clic en "Add Contact"
  const handle_AddContact = (e, row) => {
    e.preventDefault();
    e.stopPropagation(); // Evita que el evento burbujee y cierre el dropdown prematuramente
    setSelectedExam(row);

    // Obtener el clinicianAssessmentId
    const clinicianAssessmentId = row.clinicianAssessmentId || 0;

    if (parseInt(clinicianAssessmentId, 10) !== 0) {
      // Obtener datos existentes del contacto
      UserService.getContact(clinicianAssessmentId)
        .then(contact => {
          setForm({
            firstname: contact.firstName || '',
            middlename: contact.middleName || '',
            lastname: contact.lastName || '',
            email: contact.email || '',
            username: contact.username || '',
            rolevalues: contact.rolevalues || null,
            departmentvalue: contact.departmentvalue || null,
            specialtyId: contact.specialtyId || null,
            organizationId: contact.organizationId || -1,
            company: contact.company || '',
            phoneNumber: contact.phoneNumber || '',
            address: contact.address || ''
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Si no se encuentra el contacto, limpiar el formulario
            clearForm();
          } else {
            toast.error(`Error fetching contact: ${error.response?.data?.message || error.message}`);
          }
        });
    } else {
      // Si clinicianAssessmentId es 0, limpiar el formulario para crear uno nuevo
      clearForm();
    }

    toggleAddContactModal();
  };

  // Función para manejar cambios en el formulario
  const handle_OnChange = e => {
    const { name, value } = e.target;
    setForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));

    // Validaciones en tiempo real
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      switch (name) {
        case 'firstname':
          if (!value.trim()) {
            newErrors.firstname = 'First name is required';
          } else {
            delete newErrors.firstname;
          }
          break;
        case 'lastname':
          if (!value.trim()) {
            newErrors.lastname = 'Last name is required';
          } else {
            delete newErrors.lastname;
          }
          break;
        case 'email':
          if (!value) {
            newErrors.email = 'Email is required';
          } else if (!/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = 'Email is invalid';
          } else {
            delete newErrors.email;
          }
          break;
        case 'company':
          if (!value.trim()) {
            newErrors.company = 'Company is required';
          } else {
            delete newErrors.company;
          }
          break;
        case 'phoneNumber':
          if (!value.trim()) {
            newErrors.phoneNumber = 'Phone number is required';
          } else {
            delete newErrors.phoneNumber;
          }
          break;
        case 'address':
          if (!value.trim()) {
            newErrors.address = 'Address is required';
          } else {
            delete newErrors.address;
          }
          break;
        default:
          break;
      }
      return newErrors;
    });
  };

  // Función para manejar el cambio en la aceptación de términos
  const acceptHandle_OnChange = e => {
    if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
      setAccept(e.target.checked);
    }
  };

  // Función para renderizar el botón de aceptación
  const acceptRenderSubmitBtn = () => (
    <Fragment>
      <Button
        color="primary"
        disabled={!accept}
        onClick={() => {
          if (accept) {
            setShowExamDialog(!showExamDialog);
          }
          closeModalTerms();
        }}
      >
        Accept
      </Button>
    </Fragment>
  );

  // Función para cerrar el modal de términos
  const handle_OnClickCloseButton = () => {
    setAccept(false);
    closeModalTerms();
  };

  // Función para manejar el clic en un examen
  const handle_OnClick = (e, exam) => {
    e.preventDefault();
    if (exam.status.toLowerCase() === 'new') {
      openModalTerms();
    } else {
      setShowExamDialog(!showExamDialog);
    }
    setCurrentExam(exam);
  };

  // Función para mostrar el reporte del examen
  const handle_OnShowReport = (e, row) => {
    e.preventDefault();
    e.stopPropagation(); // Evita que el evento burbujee y cierre el dropdown prematuramente
    const examType = row.examType.toLowerCase();
    history.push(`/exam/report/${row.examId}/${currentuser.id}/${row.clinicianAssessmentId}/${examType}`);
  };

  // Función para manejar la descarga del reporte en PDF
  const handle_OnDownloadReport = async (e, row) => {
    function getReportData(examId, userId, clinicianAssessmentId) {
      return ExamService.reportExam(examId, userId, clinicianAssessmentId);
    }
    function getContactData(clinicianAssessmentId) {
      return userService.getContactExt(clinicianAssessmentId);
    }
    const blobToBase64 = (blob, callback) => {
      var reader = new FileReader();
      reader.onload = function() {
        var dataUrl = reader.result;
        callback(dataUrl);
      };
      reader.readAsDataURL(blob);
    };

    e.preventDefault();
    e.stopPropagation();

    let dataPromise = getReportData(row.examId, currentuser.id, row.clinicianAssessmentId);
    let contactPromise = getContactData(row.clinicianAssessmentId);

    Promise.all([dataPromise, contactPromise])
      .then(values => {
        const reportData = values[0].reportData;
        const contact = values[1];

        const data = {
          clinician: reportData.userClinician,
          score: reportData.groupscore,
          exam: reportData.exam,
          department: reportData.userClinician.department,
          organization: reportData.userClinician.department.organization,
          specialty: reportData.userClinician.specialtyResponse,
          reportdata: reportData,
          incorrectAnswersList: values[0].incorrectAnswersList,
          passingScore: reportData.passingScore
        };

        if (!data.organization) return;

        // Si la organización tiene logo, lo obtenemos para el PDF
        if (data.organization.isLogo) {
          fileService
            .getOrganization_logo(data.organization.organizationId)
            .then(response => {
              if (response.data && response.data.size > 0) {
                const newBlob = new Blob([response.data], { type: 'image/jpeg' });
                blobToBase64(newBlob, pbase64 => {
                  let avatarData = [{ base64: pbase64 }];
                  setAvatar(avatarData);

                  const pdfFactory = UserFormPDF({ avatar: avatarData, data, contact });
                  const doc = pdfFactory.createPdfWithUserData();
                  doc.then(report => {
                    const pdfManager = new PdfManager(report);
                    pdfManager.savePdf(`${data.exam.examName}_report_${row.clinicianAssessmentId}`);
                  });
                });
              }
            })
            .catch(error => {
              const resMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              toast.error(`Try again! ${resMessage}`);
            });
        } else {
          // Sin logo
          const pdfFactory = UserFormPDF({ avatar: null, data, contact });
          const doc = pdfFactory.createPdfWithUserData();
          doc.then(report => {
            const pdfManager = new PdfManager(report);
            pdfManager.savePdf(`${data.exam.examName}_report_${row.clinicianAssessmentId}`);
          });
        }
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      });
  };

  // Función para manejar el envío del formulario de "Add Contact"
  const handle_SaveContact = e => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please fix the errors in the form');
      return;
    }

    const clinicianAssessmentId = selectedExam?.clinicianAssessmentId || 0;
    const contactData = {
      firstName: form.firstname,
      middleName: form.middlename,
      lastName: form.lastname,
      email: form.email,
      company: form.company,
      address: form.address,
      phoneNumber: form.phoneNumber
    };

    UserService.saveContact(clinicianAssessmentId, contactData)
      .then(() => {
        toast.success('Contact updated successfully');
        toggleAddContactModal();
        clearForm();
        getExamListFiltered(currentuser.id, status);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        const errorMessage =
          clinicianAssessmentId === 0
            ? `Error creating contact: ${resMessage}`
            : `Error updating contact: ${resMessage}`;
        toast.error(errorMessage);
      });
  };

  /**
   * Maneja la acción de editar o borrar un examen.
   * Si la acción es "delete", mostramos el modal de confirmación;
   * en caso contrario, invocamos directamente el servicio.
   */
  const handle_EditExam = (e, row, action) => {
    e.preventDefault();
    e.stopPropagation();

    if (action === 'delete') {
      setExamToDelete(row);
      setShowConfirmDelete(true);
      return;
    }

    // Para otras acciones distintas de "delete"
    ExamService.alterExam(row.clinicianAssessmentId, currentuser.id, action)
      .then(() => {
        const successMessage = `Exam ${action === 'edit' ? 'edited' : 'deleted'} successfully`;
        toast.success(successMessage);
        getExamListFiltered(currentuser.id, status);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(`Error processing exam: ${resMessage}`);
      });
  };

  /**
   * Función que confirma el borrado del examen (cuando el usuario hace clic en "Confirm")
   */
  const confirmDeleteExam = () => {
    if (!examToDelete) return;

    ExamService.alterExam(examToDelete.clinicianAssessmentId, currentuser.id, 'delete')
      .then(() => {
        toast.success('Exam deleted successfully');
        getExamListFiltered(currentuser.id, status);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(`Error deleting exam: ${resMessage}`);
      })
      .finally(() => {
        setShowConfirmDelete(false);
        setExamToDelete(null);
      });
  };

  // Función para cancelar y cerrar el modal de "Add Contact"
  const cancelButton_OnClick = () => {
    toggleAddContactModal();
    clearForm();
  };

  const getExamListFiltered = examstatus => {
    ExamService.allByUserFiltered(currentuser.id, examstatus)
      .then(examsResponse => {
        setIsLoadedExamList(false);
        setExamList(examsResponse);
        setIsLoadedExamList(true);
        setListHasChange(true);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
      });
  };

  // Actualiza la lista de exámenes cuando examStateChanged cambie a true
  useEffect(() => {
    if (!examStateChanged) return;
    ExamService.allByUserFiltered(currentuser.id, status)
      .then(examsResponse => {
        setIsLoadedExamList(false);
        setExamList(examsResponse);
        setIsLoadedExamList(true);
        setListHasChange(true);
        setExamStateChanged(false);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
      });
  }, [status, currentuser.id, examStateChanged, setListHasChange]);

  // Carga la lista de exámenes al montar o cuando cambie "status"
  useEffect(() => {
    ExamService.allByUserFiltered(currentuser.id, status)
      .then(examsResponse => {
        setIsLoadedExamList(false);
        setExamList(examsResponse);
        setIsLoadedExamList(true);
        setListHasChange(true);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
      });
  }, [status, currentuser.id, setListHasChange]);

  // Carga información de la organización del usuario al montar
  useEffect(() => {
    UserService.find(currentuser.id)
      .then(userResponse => {
        setOrganizationName(userResponse.data.department.organization.organizationName);
        setOrganizationType(userResponse.data.department.organization.type);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Formateadores para las columnas de la tabla
  const actionFormatter = (dataField, row) => {
    const disabled = row ? row.examQuestionCount === null || row.examQuestionCount <= 1 : true;

    if (row) {
      switch (row.status.toLowerCase()) {
        case 'new':
        case 'initiated':
        case 'in progress':
          return (
            <Button
              color={`falcon-${disabled ? 'dark' : 'success'}`}
              disabled={disabled}
              onClick={e => handle_OnClick(e, row)}
            >
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        case 'complete':
          return (
            <Fragment>
              {row.examType.toLowerCase() === 'exam' && (
                <ExamResult row={row} onClick={handle_OnShowReport} />
              )}
              {row.examType.toLowerCase() === 'checklist' && (
                <CheckListResult row={row} onClick={handle_OnShowReport} />
              )}
            </Fragment>
          );
        default:
          return <Badge color="soft-danger" className="mr-2" pill />;
      }
    }
    return null;
  };

  const viewFormatter = (dataField, row) => {
    if (row) {
      return (
        <HoverableBadge
          row={row}
          handle_OnShowReport={handle_OnShowReport}
          handle_OnDownloadReport={handle_OnDownloadReport}
          handle_AddContact={handle_AddContact}
          handle_EditExam={handle_EditExam}
        />
      );
    }
    return null;
  };

  const columns = [
    ...reportIdColumn(organizationType),
    ...descriptionColumn(organizationType),
    ...typeColumn(organizationType),
    ...statusColumn(),
    {
      dataField: 'examscore',
      headerClasses: 'border-0 ',
      text: isTruck() ? '' : 'Score %',
      classes: 'border-0 py-2 align-middle font-weight-bold',
      align: 'center',
      headerAlign: 'center',
      formatter: actionFormatter
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-right',
      text: 'Report',
      classes: 'border-0 py-2 align-middle text-right font-weight-bold',
      formatter: viewFormatter
    }
  ];

  // Función para manejar la visibilidad del ExamDialog
  const setExamDialogVisible = value => {
    setShowExamDialog(value);
  };

  // Función de validación del formulario "Add Contact"
  const validateForm = () => {
    const newErrors = {};

    if (!form.firstname.trim()) {
      newErrors.firstname = 'First name is required';
    }
    if (!form.lastname.trim()) {
      newErrors.lastname = 'Last name is required';
    }
    if (!form.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!form.company.trim()) {
      newErrors.company = 'Company is required';
    }
    if (!form.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required';
    }
    if (!form.address.trim()) {
      newErrors.address = 'Address is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Eventos de fila para la tabla (por ejemplo, doble clic)
  const tableExamListRowEvents = {
    onDoubleClick: (e, row) => {
      if (!row) return;

      if (row.status.toLowerCase() === 'new' || row.status.toLowerCase() === 'in progress') {
        handle_OnClick(e, row);
      } else if (row.status.toLowerCase() === 'complete') {
        handle_OnShowReport(e, row);
      }
    }
  };

  return (
    <Fragment>
      {/* Modal de confirmación para "Delete Exam" */}
      <Modal isOpen={showConfirmDelete} toggle={() => setShowConfirmDelete(!showConfirmDelete)}>
        <ModalHeader>Delete Exam</ModalHeader>
        {/* <ModalBody>
          {examToDelete ? `Are you sure you want to delete ${examToDelete.examName}?` : 'Are you sure you want to delete this assessment?'}
        </ModalBody> */}
        <ModalBody>
          {examToDelete
            ? `Are you sure you want to delete ${examToDelete.examName} with report ID ${examToDelete.clinicianAssessmentId}?`
            : 'Are you sure you want to delete this assessment?'}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={confirmDeleteExam}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal de Términos y Condiciones */}
      <HcareModal
        title="Terms & Conditions - User Rights:"
        isOpen={isOpenTerms}
        closeModal={handle_OnClickCloseButton}
        submitbtn={acceptRenderSubmitBtn}
        className="terms-conditions"
      >
        <AssessmentTermsConditions />
        <FormGroup className="form-check" style={{ marginTop: '2rem' }}>
          <Input
            type="checkbox"
            name="accept"
            id="accept"
            checked={accept}
            onChange={acceptHandle_OnChange}
          />
          <Label for="accept" check>
            I agree to terms and conditions of escoreit software
          </Label>
        </FormGroup>
      </HcareModal>

      {/* Modal para "Add Contact" */}
      <Modal isOpen={isAddContactModalOpen} toggle={toggleAddContactModal} size="lg">
        <ModalHeader toggle={toggleAddContactModal}>
          {selectedExam?.contactInfo === '' || selectedExam?.contactInfo === null
            ? 'Create Contact'
            : 'Update Contact'}
        </ModalHeader>
        <ModalBody>
          {selectedExam && <UserGuidedForm form={form} handle_OnChange={handle_OnChange} errors={errors} />}
        </ModalBody>
        <ModalFooter>
          <Button color="falcon-primary" className="mr-2" type="submit" onClick={handle_SaveContact}>
            {selectedExam?.contactInfo === '' || selectedExam?.contactInfo === null ? 'Create' : 'Update'}
          </Button>
          <Button color="falcon-default" type="button" onClick={cancelButton_OnClick}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dialogo del Examen */}
      {currentExam && (
        <ExamDialog
          visible={showExamDialog}
          setVisible={setExamDialogVisible}
          user={currentuser}
          exam={currentExam}
          backdrop="static"
          setExamStateChanged={setExamStateChanged}
        />
      )}

      {/* Loader mientras se carga la lista de exámenes */}
      {!isLoadedExamList && <Loader />}

      {/* Tabla de Exámenes */}
      {isLoadedExamList && currentuser.roles.includes('user') && (
        <Card className="mb-3 table-container">
          <CardBody className="bg-light">
            <PageFilterableTable
              data={examList}
              columns={columns}
              keyField="clinicianAssessmentId"
              title={organizationName}
              enableNewButton={false}
              sizePerPage={8}
              rowEvents={tableExamListRowEvents}
            />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

// Validación de Props
ExamList.propTypes = {
  setListHasChange: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired
};

export default ExamList;
