import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

const TabItem = ({ tabId, toggle, activeTab, title, onclick, tooltip, ...rest }) => {
  const target = `tabitem_${tabId}`;

  const ModifiedToolTip = ({ tooltip, target }) => {
    if (tooltip && target) {
      return React.cloneElement(tooltip, { ...tooltip.props, target });
    }
    return null;
  };

  return (
    <>
      <NavItem id={`tabitem_${tabId}`} className="cursor-pointer">
        <NavLink
          className={classNames({ active: activeTab === tabId })}
          onClick={() => {
            if (typeof onclick === 'function') {
              onclick();
            }
            toggle(tabId);
          }}
        >
          {title}
        </NavLink>
      </NavItem>
      {tooltip && <ModifiedToolTip tooltip={tooltip} target={target} />}
    </>
  );
};

const TapContentItem = ({ tabId, tabContent, ...rest }) => {
  return (
    <TabPane tabId={tabId}>
      <Row>
        <Col sm="12 mt-4">{tabContent}</Col>
      </Row>
    </TabPane>
  );
};

const CustomTab = ({ activeTab, setActiveTab, tabContents, ...rest }) => {
  const toggle = tabId => {
    if (activeTab !== tabId) setActiveTab(tabId);
  };

  return (
    <Fragment>
      <Nav tabs>
        {Object.keys(tabContents).map((key, index) => {
          if (tabContents[key].tapitem) {
            return React.cloneElement(tabContents[key].tapitem, { key: index });
          } else {
            return (
              <TabItem
                key={index}
                tabId={`${index}`}
                toggle={toggle}
                activeTab={activeTab}
                title={tabContents[key].title}
                onclick={tabContents[key].onclick}
                tooltip={tabContents[key].tooltip}
              />
            );
          }
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Object.keys(tabContents).map((key, index) => {
          return <TapContentItem key={index} tabId={`${index}`} tabContent={tabContents[key].content} />;
        })}
      </TabContent>
    </Fragment>
  );
};

export default CustomTab;
